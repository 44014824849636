import React from 'react'
import {container,section,title,description,highlight} from  "../css/AboutUs.module.css";
import NavBar from '../section/NavBar/NavBar'

export default function AboutUs(){
    return(
        <>
          <NavBar />
     <div className={container}>

  <div className={section}>
    <h2 className={title}>Our Company</h2>
    <p className={description}>
      Sansar Constructions is a company established in 2021, offering integrated planning, structural designs, 
      execution of works, repair & rehabilitation of buildings, and consultancy services for all types of infrastructure projects. 
    </p>
    <p className={description}>
      The company excels in innovative planning, quality construction, and providing excellent consultancy services. 
      With expertise in handling institutional, commercial, official, and residential buildings, as well as roads and culverts, 
      Sansar Constructions is committed to maintaining the highest standards in design and execution.
    </p>
  </div>

  <div className={section}>
    <h2 className={title}>Our Founder</h2>
    <p className={description}>
      The company was founded by <span className={highlight}>Dr. A. Annadurai, B.E. (Civil), M.E. (Structural), Ph.D.,</span>who has extensive experience in the construction 
      industry. His portfolio includes the successful completion of various institutional, commercial, residential, and infrastructure projects.
    </p>
    <p className={description}>
      Dr. Annadurai is known for his integrity and ability to coordinate with clients, architects, PWD engineers, suppliers, and employees at all levels. 
      His leadership and technical expertise ensure that Sansar Constructions consistently delivers projects of high quality and excellence.
    </p>
  </div>
</div>
</>
    )
}