import React from 'react'
import {yellowGradient,blueGradient, step } from './Button.module.css'
import PropTypes from 'prop-types'

export function YGButton({children, buttonStyle}) {
  return (
    <button className={`${buttonStyle} ${yellowGradient}`}>{children}</button>
  )
}

export function BGButton({children, buttonStyle, ...rest}) {
  return (
    <button className={`${buttonStyle} ${blueGradient}`} {...rest}>{children}</button>
  )
}

export function RippleButton({children}){
  return (
     <div className={step}>
      {children}
     </div>
  )
}

RippleButton.propTypes = {
  children: PropTypes.node.isRequired,
}

BGButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonStyle: PropTypes.object
}

YGButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonStyle: PropTypes.object
}