import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';
import NavBar from '../section/NavBar/NavBar'

import {cardTitle,border,section} from '../css/gallery.module.css'

const STRUCTURE_IMAGES = [
  {
    title: "Structure 1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "structure1.png"
  },
  {
    title: "Structure 2",
    size: "Default Size",
    description: "Description for plan2.png",
    imageName: "structure2.png"
  },
  {
    title: "Structure 3",
    size: "Default Size",
    description: "Description for plan3.png",
    imageName: "structure3.png"
  },
  {
    title: "Structure 4",
    size: "Default Size",
    description: "Description for plan4.png",
    imageName: "structure4.png"
  },
  {
    title: "Structure 5",
    size: "Default Size",
    description: "Description for plan5.png",
    imageName: "structure5.png"
  }
]

function Gallery() {
      const data = useStaticQuery(graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      `);
    
      const getImageByName = (name) => {
        const match = data.allFile.edges.find(({ node }) => node.relativePath === name);
        return match ? getImage(match.node) : null;
      };

  return (
  <>
  <NavBar />
  <Container className={section}>
    <div className={border}>
    <h3 id='plans' className={cardTitle}>
      Structural Designs
    </h3>
    </div>
  {
    STRUCTURE_IMAGES.map((structure,index)=> <GatsbyImage image={getImageByName(structure.imageName)} alt={structure.title} key={index} style={{width:366, height:'auto', margin:'10px'}} />)
  }
  </Container>
  </>)
       
}

export default Gallery;