import React from 'react';
import {header,section,subheader,button,alignContent} from './Introduction.module.css';
import {YGButton} from '../../components/Button/Button';
import { StaticImage } from 'gatsby-plugin-image';

const Introduction = () => {
    return (
        <>
        <section className={section} id="home">
            <StaticImage src='./assets/introduction.jpg'
  style={{ position: 'absolute', top: '8vh', left: 0, width: '100%', height: '71.5vh', zIndex: '-1', borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px"  }} placeholder='TRACED_SVG' loading="eager" />
            <div className={alignContent}>
            <h1 className={header}>
                Designers 
                Builders
                Promoters
            </h1>
            <p className={subheader}>
                Building Quality, Building Trust - Your Vision, Our Commitment
            </p>
            <a href='#collaborate'>
            <YGButton buttonStyle={button}>Collaborate with us</YGButton>
            </a>
            </div>
        </section>
        </>
    )
}

export default Introduction;