import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { StaticImage } from 'gatsby-plugin-image';

function SaleModal({setShowModal,showModal}) {
  
  const handleClose = () => setShowModal(false);
  
  return (
     <Modal show={showModal} fullScreen={true} onHide={handleClose} >
        <Modal.Header closeButton />
        <Modal.Body>
            <StaticImage
                alt="construction company"
                src="../images/home_for_sale.jpeg"
            />
        </Modal.Body>
      </Modal>
  );
}

export default SaleModal;