import React from 'react'
import {section,header,textHighlight,content,button} from './Invite.module.css'
import { YGButton } from '../../components/Button/Button';

function Invite() {
  return (
   <section className={section} id='collaborate'>
      <h3 className={header}>
        Inviting<br/> <span className={textHighlight}>Land Owners</span><br/> for a profitable<br/><span className={textHighlight}>Joint Venture</span> 
      </h3>
      <p className={content}>
        For Individual Villas and Apartments in and around Kanchipuram.
      </p>
      <a href='tel:9790434734'>
      <YGButton buttonStyle={button}>
        Let&apos;s talk
      </YGButton>
      </a>
   </section>
  )
}

export default Invite;