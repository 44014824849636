import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import Card from 'react-bootstrap/Card';
import {section,header,card,button,image,frame} from './Projects.module.css'
import { FaArrowRightLong } from "react-icons/fa6";
import { BGButton } from '../../components/Button/Button';
import { Link } from 'gatsby';

function Projects() {
  return (
    <section className={section}>
    <p className={header}>Projects</p>
    <Card className={card}>
      <div className={frame}>
      <StaticImage alt='kilambi project' src="./assets/kilambi.jpg" className={image} />
      </div>
    </Card>
    <Link to='kilambi' >
    <BGButton buttonStyle={button} > Explore more <FaArrowRightLong />   </BGButton>
    </Link>
    </section>
  );
}

export default Projects;