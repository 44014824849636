import React, { useState } from 'react';
import Introduction from '../section/Introduction/Introduction';
import '../css/global.module.css';
import {Seo}  from '../section/Seo';
import NavBar from '../section/NavBar/NavBar';
import Services from '../section/Services/Services';
import GetQuote from '../section/GetQuote/GetQuote';
import SaleModal from '../section/SaleModal';
import Projects from '../section/Projects/Projects';
import Process from '../section/Process/Process';
import Invite from '../section/Invite/Invite';
import { RippleButton } from '../components/Button/Button';
import { SiWhatsapp } from "react-icons/si";
import Contact from '../section/Contacts/Contact';
import FaqSection from '../section/Faq/Faq';

const Home = () => {

const [showModal, setShowModal] = useState(false)
  
  return(
    <>
      <NavBar />
      <Introduction/>
      {
        showModal ? <SaleModal setShowModal={setShowModal} showModal={showModal} /> : null
      }
      <Services/>
      <Process/>
      <Projects />
      <Invite/>
      <GetQuote />
      <FaqSection/>
      <Contact/>
       <a href={`https://wa.me/919790434734?text=I would like to know more about your company and services`}>
      <RippleButton > 
        <SiWhatsapp style={{color:'white', fontSize:'2.5rem'}} /> 
      </RippleButton>
      </a>
    </>
  )
}

export default Home;

export const Head = () => (
  <Seo />
)