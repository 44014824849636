import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';
import NavBar from '../section/NavBar/NavBar'
import {cardTitle,border,section} from '../css/gallery.module.css'

const CONSTRUCTION_IMAGES = [
  {
    title: "CONSTRUCTION 1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "construction1.jpeg"
  },
  {
    title: "CONSTRUCTION 2",
    size: "Default Size",
    description: "Description for plan2.jpeg",
    imageName: "construction2.jpeg"
  },
  {
    title: "CONSTRUCTION 3",
    size: "Default Size",
    description: "Description for plan3.jpeg",
    imageName: "construction3.jpeg"
  },
  {
    title: "CONSTRUCTION 4",
    size: "Default Size",
    description: "Description for plan4.jpeg",
    imageName: "construction4.jpeg"
  },
  {
    title: "CONSTRUCTION 5",
    size: "Default Size",
    description: "Description for plan5.jpeg",
    imageName: "construction5.jpeg"
  }
]

function Gallery() {
      const data = useStaticQuery(graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      `);
    
      const getImageByName = (name) => {
        const match = data.allFile.edges.find(({ node }) => node.relativePath === name);
        return match ? getImage(match.node) : null;
      };

  return (
  <>
  <NavBar />
  <Container className={section}>
      <div className={border}>
          <h3 id='construction' className={cardTitle}>Construction</h3>
      </div>
  {
    CONSTRUCTION_IMAGES.map((con,index)=> <GatsbyImage image={getImageByName(con.imageName)} alt={con.title} key={index} style={{width:366, height:'auto', margin:'10px'}} loading={index === 0 ? 'eager' : 'lazy'} />)
  }
  </Container>
  </>)
       
}

export default Gallery;