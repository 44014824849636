import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {section,header} from './Faq.module.css'
import './faq.css'

function FaqSection() {
  return (
    <section className={section}>
    <p className={header}>FAQ</p>
    <Accordion className='mt-5 accordian'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Do you provide custom house designs?</Accordion.Header>
        <Accordion.Body>
          Yes, we will do custom planning,structural designing and construction according to the client interests. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How long does it take to complete a construction project?</Accordion.Header>
        <Accordion.Body>
          It will take minimum of 6 months. Depending on the area it will vary. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Do you provide 3D visualization or models of the project?</Accordion.Header>
        <Accordion.Body>
         Yes we will provide 3D visualization.
        </Accordion.Body>
      </Accordion.Item>
        <Accordion.Item eventKey="3">
        <Accordion.Header>What sets your company apart from other construction firms?</Accordion.Header>
        <Accordion.Body>
            You can expect design and construction with high quality and finesse
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </section>
  );
}

export default FaqSection;