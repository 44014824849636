import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';
import NavBar from '../section/NavBar/NavBar'
import Header from '../components/Header/Header'

import {cardTitle,border} from '../css/gallery.module.css'

const PLAN_IMAGES = [
  {
    title: "PLAN1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "plan1.png"
  },
  {
    title: "PLAN2",
    size: "Default Size",
    description: "Description for plan2.png",
    imageName: "plan2.png"
  },
  {
    title: "PLAN3",
    size: "Default Size",
    description: "Description for plan3.png",
    imageName: "plan3.png"
  },
  {
    title: "PLAN4",
    size: "Default Size",
    description: "Description for plan4.png",
    imageName: "plan4.png"
  },
  {
    title: "PLAN5",
    size: "Default Size",
    description: "Description for plan5.png",
    imageName: "plan5.png"
  }
]

const STRUCTURE_IMAGES = [
  {
    title: "Structure 1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "structure1.png"
  },
  {
    title: "Structure 2",
    size: "Default Size",
    description: "Description for plan2.png",
    imageName: "structure2.png"
  },
  {
    title: "Structure 3",
    size: "Default Size",
    description: "Description for plan3.png",
    imageName: "structure3.png"
  },
  {
    title: "Structure 4",
    size: "Default Size",
    description: "Description for plan4.png",
    imageName: "structure4.png"
  },
  {
    title: "Structure 5",
    size: "Default Size",
    description: "Description for plan5.png",
    imageName: "structure5.png"
  }
]

const CONSTRUCTION_IMAGES = [
  {
    title: "CONSTRUCTION 1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "construction1.jpeg"
  },
  {
    title: "CONSTRUCTION 2",
    size: "Default Size",
    description: "Description for plan2.jpeg",
    imageName: "construction2.jpeg"
  },
  {
    title: "CONSTRUCTION 3",
    size: "Default Size",
    description: "Description for plan3.jpeg",
    imageName: "construction3.jpeg"
  },
  {
    title: "CONSTRUCTION 4",
    size: "Default Size",
    description: "Description for plan4.jpeg",
    imageName: "construction4.jpeg"
  },
  {
    title: "CONSTRUCTION 5",
    size: "Default Size",
    description: "Description for plan5.jpeg",
    imageName: "construction5.jpeg"
  }
]

function Gallery() {
      const data = useStaticQuery(graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      `);
    
      const getImageByName = (name) => {
        const match = data.allFile.edges.find(({ node }) => node.relativePath === name);
        return match ? getImage(match.node) : null;
      };

  return (
  <>
  <NavBar />
  <Container style={{marginTop:'10vh'}}>
    <Header text={'Gallery'} headerStyle={{margin:'30px 0'}} />
    <div className={border}>
          <h3 id='construction' className={cardTitle}>Construction</h3>
    </div>
  {
    CONSTRUCTION_IMAGES.map((con,index)=> <GatsbyImage image={getImageByName(con.imageName)} alt={con.title} key={index} style={{width:366, height:'auto', margin:'10px'}} loading={index === 0 ? 'eager' : 'lazy'} />)
  }

        <div className={border}>
          <h3 id='structure' className={cardTitle}>Stuctures</h3>
      </div>
  
  {
    STRUCTURE_IMAGES.map((structure,index)=> <GatsbyImage image={getImageByName(structure.imageName)} alt={structure.title} key={index} style={{width:366, height:'auto', margin:'10px'}} loading={index === 0 ? 'eager' : 'lazy'} />)
  }

    <div className={border}>
      <h3 id='plans' className={cardTitle}>
      Plans
      </h3>
    </div>
  {
    PLAN_IMAGES.map((plan,index)=> <GatsbyImage image={getImageByName(plan.imageName)} alt={plan.title} key={index} style={{width:366, height:'auto', margin:'10px'}} loading={index === 0 ? 'eager' : 'lazy'} />)
  }

  </Container>
  </>)
       
}

export default Gallery;