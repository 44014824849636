import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import {section,header,mapAlign,socialMedia} from './Contact.module.css'
import Wave from './assets/wave.svg'
import { BsInstagram } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";

const Contact = () => {
  return (
    <div className={section} id="contact">
      <div style={{height:'27vh'}}>
        <Wave/>
      </div>
      <Container>
        <p className={header}>Reach us at</p>
        <div className={mapAlign}>
        <iframe
          title="sansar constructions in google map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.387800971179!2d79.72968211464246!3d12.818198621694336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52c34a898fcded%3A0x2771eddf26ce647e!2sSansar%20Constructions!5e0!3m2!1sen!2sin!4v1658674210013!5m2!1sen!2sin"
          width="600"
          height="300"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <div className={socialMedia}>
        <Row className="p-4 justify-content-md-center">
          <Col xs='6' lg="5" className="py-2 justify-content-md-center">
          <a href="https://www.instagram.com/sansarconstructions" target="_blank" rel="noreferrer">
            <BsInstagram/> Instagram
          </a>
          </Col>

          
          <Col xs='6' lg="5" className="py-2 justify-content-md-center">
          <a href='https://www.facebook.com/SansarConstructions' target="_blank" rel="noreferrer" >
            <FaFacebookSquare/> Facebook
          </a>
          </Col>
          
          <Col xs='6' lg="5" className="py-2 justify-content-md-center"> 
          <a href="https://www.linkedin.com/company/sansar-constructions" target="_blank" rel="noreferrer">
            <FaLinkedin/> LinkedIn
          </a>
          </Col>

          <Col xs='6' lg="5" className="py-2 justify-content-md-center"> 
          <a href="tel:+919790434734">
            <IoCall /> Call
          </a>
          </Col>
        </Row>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
