import React from 'react'
import {section,process,container,iconContainer,title,icon} from './Process.module.css'
import { MdOutlineArchitecture } from "react-icons/md";
import { Container } from 'react-bootstrap';
import { LuFrame } from "react-icons/lu";
import { FaBuilding } from "react-icons/fa6";
import Header from '../../components/Header/Header';

function Process() {
  return (
  <>
      {/* <div className={highlightContainer}>
      <h3 className={header} >Our basic rate for construction</h3>
      <span className={textHighlight}>2300 per sq.ft</span>
      </div> */}
        <section className={section}>
       <Header headerStyle={{fontSize:'3rem'}} text={'Our Process'} />
      <Container className={container} >
        <div className={process}>
            <div className={iconContainer}>
            <MdOutlineArchitecture className={icon}/>
          </div>
          <p className={title}>Planning</p>
        </div>
        <div className={process}>
        <div className={iconContainer}>
          <LuFrame className={icon}/>
        </div>
      <p className={title}>Design</p>
        </div>
        <div className={process}>
        <div className={iconContainer}>
          <FaBuilding className={icon}/>
        </div>
      <p className={title}>Construction</p>
        </div>
      </Container>
    </section>
  </>
  )
}

export default Process