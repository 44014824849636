import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { StaticImage } from 'gatsby-plugin-image';
import NavBar from '../section/NavBar/NavBar';
import '../css/kilambi.css';
import { FaRupeeSign } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

function Kilambi() {
  return (
    <>
    <NavBar/>
    <Carousel slide className='carousel' >
      <Carousel.Item>
        <StaticImage alt='kilambi project' src="../images/kilambi/kilambi.webp" placeholder='TRACED_SVG' loading="eager"  />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/entrance.jpg`}  placeholder='TRACED_SVG' loading="lazy"  />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/hall.jpg`}  placeholder='TRACED_SVG' loading="lazy" />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/bedroom.jpg`}   placeholder='TRACED_SVG' loading="lazy"/>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/bedroom_2.jpg`}  placeholder='TRACED_SVG' loading="lazy" />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/kitchen.jpg`}  placeholder='TRACED_SVG' loading="lazy" />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage alt='kilambi project' src={`../images/kilambi/portico.jpg`}  placeholder='TRACED_SVG' loading="lazy" />
      </Carousel.Item>
    </Carousel>
    <div className='button'>
        <div>
        <FaRupeeSign /> 38 Lakhs
        </div>
        <div>
        <a href='https://www.google.com/maps/@12.8594835,79.6546992,3a,75y,357.02h,81.64t/data=!3m7!1e1!3m5!1s6pL4ri6ejd9g643HxFZ2PQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D8.36%26panoid%3D6pL4ri6ejd9g643HxFZ2PQ%26yaw%3D357.02!7i13312!8i6656?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D'>
        <IoLocationSharp /> Kilambi, Kanchipuram
        </a>
        </div>

    </div>
    </>
  )
}

export default Kilambi