import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import {section,header,inputContainer,button,formContainer,input} from './GetQuote.module.css'
import { BGButton } from '../../components/Button/Button';

export default function GetQuoteModal () {
    const [form,setForm] =  useState({
        name:'',
        phoneNumber:'',
        address:'',
        purpose:''
    });
   
    function onChange(e){        
      setForm({[e.target.name]:e.target.value});
    }

    const handleSubmit = event => {
  event.preventDefault();

  const myForm = event.target;
  const formData = new FormData(myForm);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString()
  })
    .then(() => setForm({
        name:'',
        phoneNumber:'',
        address:'',
        purpose:''
    }) )
    .catch(error => alert(error));
};

  return (
    <section className={section}>
      <h2 className={header}>Get Quote</h2>
      <form className={formContainer}  data-netlify="true"
    name="getQuote"
    method="post"
    onSubmit={handleSubmit}>
        <div className={inputContainer}>

            <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name='name'
                value={form.name}
                onChange={onChange}
                className={input}
              />
             </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name='phoneNumber'
                value={form.phoneNumber}
                onChange={onChange}
                className={input}
              />
              </Form.Group>
             <Form.Group
              className="mb-3"
            >
              <Form.Label>Address</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3}    
                name='address'
                value={form.address}
                onChange={onChange}
                className={input}
                 />
            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Form.Label>Purpose</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3}
                name='purpose'
                value={form.purpose}
                onChange={onChange}
                className={input}
              />
            </Form.Group>
        </div>
          <BGButton buttonStyle={button} type='submit'>Get Quote</BGButton>
      </form>  
    </section>
  );
}
