import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';
import NavBar from '../section/NavBar/NavBar'

import {cardTitle,border,section} from '../css/gallery.module.css'

const PLAN_IMAGES = [
  {
    title: "PLAN1",
    size: "Default Size",
    description: "Description for plan1.png",
    imageName: "plan1.png"
  },
  {
    title: "PLAN2",
    size: "Default Size",
    description: "Description for plan2.png",
    imageName: "plan2.png"
  },
  {
    title: "PLAN3",
    size: "Default Size",
    description: "Description for plan3.png",
    imageName: "plan3.png"
  },
  {
    title: "PLAN4",
    size: "Default Size",
    description: "Description for plan4.png",
    imageName: "plan4.png"
  },
  {
    title: "PLAN5",
    size: "Default Size",
    description: "Description for plan5.png",
    imageName: "plan5.png"
  }
]

function Gallery() {
      const data = useStaticQuery(graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder:TRACED_SVG)
                }
              }
            }
          }
        }
      `);
    
      const getImageByName = (name) => {
        const match = data.allFile.edges.find(({ node }) => node.relativePath === name);
        return match ? getImage(match.node) : null;
      };

  return (
  <>
  <NavBar />
  <Container className={section} >
    <div className={`${border}`}>
      <h3 id='plans' className={cardTitle}>
      Plans
      </h3>
    </div>
  {
    PLAN_IMAGES.map((plan,index)=> <GatsbyImage image={getImageByName(plan.imageName)} alt={plan.title} key={index} style={{width:366, height:'auto', margin:'10px'}} />)
  }
  </Container>
  </>)
       
}

export default Gallery;