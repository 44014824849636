import React from 'react';
import Header from '../../components/Header/Header';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GoDotFill } from "react-icons/go";
import './Service.css';
import { Card, Container } from 'react-bootstrap';
import { FaArrowRightLong } from "react-icons/fa6";
const consultancyServices = [
  {
    id: 1,
    title: 'Building Plan',
    eventKey: 'plan',
    imageName: 'planning.webp',
    description: [
      'Provide CADD drawings, 2D and 3D models',
      'Professional planning for Residential, Commercial and Institutional buildings',
      'All plans are created in accordance with Vaastu',
    ],
    cta : {isPresent: true, to:'plans'}
  },
  {
    id: 2,
    title: 'Structural Design',
    eventKey: 'structure',
    imageName: 'structural.jpg',
    description: [
      'Provide structural consultancy for Architects and contractors',
      'Registered Structural Consultant in DTCP Kanchipuram',
      'All structural detailing strictly adhere to IS Codes',
    ],
    cta : {isPresent: true, to:'structure'}
  },
];

const constructionServices = [
  {
    id: 1,
    title: 'Construction',
    eventKey: 'construction',
    imageName: 'construction.jpg',
    description: [
      'Experienced in Residential, Commercial and Institutional buildings',
      'Skilled laborers and Quality of construction is maintained with High Standard materials (ISI) from foundation to finishing',
      'Promoting Individual Houses at affordable prices',
    ],
    cta : {isPresent: true, to:'construction'}
  },
  {
    id: 2,
    title: 'Interior',
    eventKey: 'interior',
    imageName: 'interior.jpg', 
    description: [
      'Maximize functionality and aesthetic appeal, even in compact spaces.', 
      'Visualize your dream interiors with detailed 3D renderings before execution.',
      'Expert advice on selecting the best materials, textures, and color palettes to complement your vision.'
    ],
    cta : {isPresent: false, to:''}
  },
  {
    id: 3,
    title: 'Exterior',
    eventKey: 'exterior',
    imageName: 'exterior.jpg', 
    description: [
      "Elevate the aesthetics of your building's façade with innovative design ideas.", 
      "Elevate the aesthetics of your building's façade with innovative design ideas", 
      "Our designs ensure durability while maintaining a timeless, elegant look for years to come."
    ],
    cta : {isPresent: false, to:''}
  },
];

const Services = () => {

      const data = useStaticQuery(graphql`
        query {
          allFile(filter: { extension: { regex: "/(jpg|jpeg|png|webp)/" } }) {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      `);
    
      const getImageByName = (name) => {
        const match = data.allFile.edges.find(({ node }) => node.relativePath === name);
        return match ? getImage(match.node) : null;
      };

    return(
        <sevices className={"section"} id='services'>
          <Container>
            <Header className={'headerStyle'} text={'We provide consultancy services in'} />
            <Tabs
            variant='underline'
            defaultActiveKey="plan"
            className="mb-3"
            >
            {consultancyServices.map((service) => (
                <Tab eventKey={service.eventKey} key={service.eventKey} title={service.title}>
                       {/* Dynamically Render Image */}
                       <div className={"cardBody"}>
                       <GatsbyImage
                         image={getImageByName(service.imageName)}
                         alt={service.title}
                         className={'image'}
                       />
                        
                         <div className={"descContainer"} >
                         {service.description.map((desc, index) => (
                           <Card.Text style={{margin:'6px'}} key={index}>
                             <GoDotFill className={"arrow"} />
                             {desc}
                           </Card.Text>
                         ))}
                         {
                           service.cta.isPresent ? <Link className={"cta"} to={service.cta.to}> Know more <FaArrowRightLong/> </Link> : null
                         }
                         </div>
                       </div>
                     </Tab>
                 ))}

            </Tabs>
          </Container>
          <Container className='mt-4'>
            <Header className={'headerStyle'} text={'Our talented professionals help you in'} />
            <Tabs
            variant='underline'
            defaultActiveKey="construction"
            className="mb-3"
            >
              {constructionServices.map((service) => (
                <Tab eventKey={service.eventKey} key={service.eventKey} title={service.title} className={"cardBody"} >
            {/* Dynamically Render Image */}
            <div className={"cardBody"}>
            <GatsbyImage
              image={getImageByName(service.imageName)}
              alt={service.title}
              className={'image'}
            />
           
              <div className={"descContainer"}>
              {service.description.map((desc, index) => (
                <Card.Text style={{margin:'6px'}} key={index}>
                  <GoDotFill className={"arrow"} />
                  {desc}
                </Card.Text>
              ))}
              {
                service.cta.isPresent ? <Link className={"cta"} to={service.cta.to}> Know more <FaArrowRightLong/> </Link> : null
              }
              </div>
            </div>
          </Tab>
      ))}
            </Tabs>
        </Container>
        </sevices>
    )
}

export default Services;